import { NgModule } from "@angular/core";
import { DateTimePipe } from "./date-time.pipe";
import { StringFromListPipe } from "./string-from-list.pipe";

@NgModule({
    declarations: [
        DateTimePipe,
        StringFromListPipe
    ],
    exports: [
        DateTimePipe,
        StringFromListPipe
    ]
})
export class MayaCommonUtilsModule { }
  